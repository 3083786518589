const faqItems = document.querySelectorAll('.faqItem');

if (faqItems) {

    faqItems.forEach( (item) => {
        const trigger = item.querySelector('.faqTrigger');
        const content = item.querySelector('.faqContent');

        trigger.setAttribute('aria-expanded', 'true');
        content.classList.remove('hidden');
        trigger.classList.add('is-active');
        item.classList.add('is-active');

        trigger.addEventListener('click', () => {
            // change aria-expanded on click
            let ariaExpanded = trigger.getAttribute('aria-expanded');

            if (ariaExpanded === "true") {
                ariaExpanded = "false"
            } else {
                ariaExpanded = "true"
            }

            trigger.setAttribute('aria-expanded', ariaExpanded);

            trigger.classList.toggle('is-active');
            content.classList.toggle('hidden');
            item.classList.toggle('is-active');
        })
    })
}
